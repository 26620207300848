type NestedContent = {
  subSubtitle: string;
  subSubtitleExpl?: string;
  table?: {
    headers: string[];
    rows: { [key: string]: string }[];
  };
  nestedContent?: NestedContent[];
};

type SubtitleWithExpl = {
  subtitle: string;
  subtitleExpl: string;
  nestedContent?: NestedContent[];
};

type SubtitleWithNestedContent = {
  subtitle: string;
  nestedContent: NestedContent[];
};

type PrivacyPolicyItem = {
  title: string;
  content: (SubtitleWithExpl | SubtitleWithNestedContent)[];
};

export const PrivacyPolicyData: PrivacyPolicyItem[] = [
  {
    title: "1. An Overview of Data Protection",
    content: [
      {
        subtitle: "General information",
        subtitleExpl:
          "The following information will provide you with an easy-to-navigate overview of what will happen with your personal data when you visit this website. The term 'personal data' comprises all data that can be used to personally identify you. For detailed information about the subject matter of data protection, please consult our Data Protection Declaration, which we have included beneath this copy.",
      },
      {
        subtitle: "Data recording on this website",
        nestedContent: [
          {
            subSubtitle:
              "Who is the responsible party for the recording of data on this website (i.e., the 'controller')?",
            subSubtitleExpl:
              "The data on this website is processed by the operator of the website, whose contact information is available under section 'Information about the responsible party (referred to as the 'controller' in the GDPR)' in this Privacy Policy.",
          },
          {
            subSubtitle: "How do we record your data?",
            subSubtitleExpl: `We collect your data as a result of your sharing of your data with us. This may, for instance be information you enter into our contact form.

            Other data shall be recorded by our IT systems automatically or after you consent to its recording during your website visit. This data comprises primarily technical information (e.g., web browser, operating system, or time the site was accessed). This information is recorded automatically when you access this website. 
            `,
          },
        ],
      },
      {
        subtitle: "What are the purposes we use your data for?",
        subtitleExpl:
          "A portion of the information is generated to guarantee the error-free provision of the website. Other data may be used to analyze your user patterns.",
      },
      {
        subtitle:
          "What rights do you have as far as your information is concerned?",
        subtitleExpl: `You have the right to receive information about the source, recipients, and purposes of your archived personal data at any time without having to pay a fee for such disclosures. You also have the right to demand that your data are rectified or eradicated. If you have consented to data processing, you have the option to revoke this consent at any time, which shall affect all future data processing. Moreover, you have the right to demand that the processing of your data be restricted under certain circumstances. Furthermore, you have the right to log a complaint with the competent supervising agency. 
          
          Please do not hesitate to contact us at any time if you have questions about this or any other data protection related issues. `,
      },
      {
        subtitle: "Analysis tools and tools provided by third parties",
        subtitleExpl: `There is a possibility that your browsing patterns will be statistically analyzed when your visit this website. Such analyses are performed primarily with what we refer to as analysis programs. 

For detailed information about these analysis programs please consult our Data Protection Declaration below. `,
      },
    ],
  },
  {
    title: "2. Hosting and Content Delivery Networks (CDN)",
    content: [
      {
        subtitle: "",
        subtitleExpl:
          "We are hosting the content of our website at the following provider: ",
      },
      {
        subtitle: "External Hosting",
        subtitleExpl:
          "Parts of the website are hosted externally. Personal data collected on this website are stored on the servers of the host. These may include, but are not limited to, IP addresses, contact requests, metadata and communications, contract information, contact information, names, web page access, and other data generated through a web site. The external hosting serves the purpose of fulfilling the contract with our potential and existing customers (Art. 6(1)(b) GDPR) and in the interest of secure, fast, and efficient provision of our online services by a professional provider (Art. 6(1)(f) GDPR). If appropriate consent has been obtained, the processing is carried out exclusively on the basis of Art. 6 (1)(a) GDPR and § 25 (1) TDDDG, insofar the consent includes the storage of cookies or the access to information in the user’s end device (e.g., device fingerprinting) within the meaning of the TDDDG. This consent can be revoked at any time. Our host(s) will only process your data to the extent necessary to fulfil its performance obligations and to follow our instructions with respect to such data. ",
      },
      {
        subtitle: "",
        subtitleExpl: `We are using the following host(s): 

        Hetzner Online GmbH
        Industriestr. 25
        91710 Gunzenhausen
        Germany`,
      },
      {
        subtitle: "Data processing",
        subtitleExpl:
          "We have concluded a data processing agreement (DPA) for the use of the above-mentioned service. This is a contract mandated by data privacy laws that guarantees that they process personal data of our website visitors only based on our instructions and in compliance with the GDPR. ",
      },
      {
        subtitle: "Cloudfare",
        subtitleExpl: `We use the “Cloudflare” service provided by Cloudflare Inc., 101 Townsend St., San Francisco, CA 94107, USA. (hereinafter referred to as “Cloudflare”). 

        Cloudflare offers a content delivery network with DNS that is available worldwide. As a result, the information transfer that occurs between your browser and our website is technically routed via Cloudflare’s network. This enables Cloudflare to analyze data transactions between your browser and our website and to work as a filter between our servers and potentially malicious data traffic from the Internet. In this context, Cloudflare may also use cookies or other technologies deployed to recognize Internet users, which shall, however, only be used for the herein described purpose. 
        
        The use of Cloudflare is based on our legitimate interest in a provision of our website offerings that is as error-free and secure as possible (Art. 6(1)(f) GDPR). 
        
        Data transmission to the US is based on the Standard Contractual Clauses (SCC) of the European Commission. Details can be found here: <a href="https://www.cloudflare.com/privacypolicy/" target="_blank" rel="noopener noreferrer">https://www.cloudflare.com/privacypolicy/</a>. 
        
        For more information on Cloudflare’s security precautions and data privacy policies, please follow this link: <a href="https://www.cloudflare.com/privacypolicy/" target="_blank" rel="noopener noreferrer">https://www.cloudflare.com/privacypolicy/</a>. 
        
        The company is certified in accordance with the “EU-US Data Privacy Framework” (DPF). The DPF is an agreement between the European Union and the US, which is intended to ensure compliance with European data protection standards for data processing in the US. Every company certified under the DPF is obliged to comply with these data protection standards. For more information, please contact the provider under the following link: <a href="https://www.dataprivacyframework.gov/list" target="_blank" rel="noopener noreferrer">https://www.dataprivacyframework.gov/list</a>.`,
      },
      {
        subtitle: "Data processing",
        subtitleExpl:
          "We have concluded a data processing agreement (DPA) for the use of the above-mentioned service. This is a contract mandated by data privacy laws that guarantees that they process personal data of our website visitors only based on our instructions and in compliance with the GDPR. ",
      },
    ],
  },
  {
    title: "3. General Information and Mandatory Information",
    content: [
      {
        subtitle: "Data protection",
        subtitleExpl: `The operators of this website and its pages take the protection of your personal data very seriously. Hence, we handle your personal data as confidential information and in compliance with the statutory data protection regulations and this Data Protection Declaration. 

Whenever you use this website, a variety of personal information will be collected. Personal data comprises data that can be used to personally identify you. This Data Protection Declaration explains which data we collect as well as the purposes we use this data for. It also explains how, and for which purpose the information is collected. 

We herewith advise you that the transmission of data via the Internet (i.e., through e-mail communications) may be prone to security gaps. It is not possible to completely protect data against third-party access. `,
      },
      {
        subtitle:
          "Information about the responsible party (referred to as the “controller” in the GDPR)",
        subtitleExpl: `The data processing controller on this website is: 

Cinemo GmbH 
Kaiserstr. 72 
76133 Karlsruhe 
Germany 

Phone: +49 (0)721 352500 
E-mail: info@cinemo.com 

The controller is the natural person or legal entity that single-handedly or jointly with others makes decisions as to the purposes of and resources for the processing of personal data (e.g., names, e-mail addresses, etc.). `,
      },
      {
        subtitle: "Storage duration",
        subtitleExpl:
          "Unless a more specific storage period has been specified in this privacy policy, your personal data will remain with us until the purpose for which it was collected no longer applies. If you assert a justified request for deletion or revoke your consent to data processing, your data will be deleted, unless we have other legally permissible reasons for storing your personal data (e.g., tax or commercial law retention periods); in the latter case, the deletion will take place after these reasons cease to apply. ",
      },
      {
        subtitle:
          "General information on the legal basis for the data processing on this website ",
        subtitleExpl:
          "If you have consented to data processing, we process your personal data on the basis of Art. 6(1)(a) GDPR or Art. 9 (2)(a) GDPR, if special categories of data are processed according to Art. 9 (1) DSGVO. In the case of explicit consent to the transfer of personal data to third countries, the data processing is also based on Art. 49 (1)(a) GDPR. If you have consented to the storage of cookies or to the access to information in your end device (e.g., via device fingerprinting), the data processing is additionally based on § 25 (1) TDDDG. The consent can be revoked at any time. If your data is required for the fulfillment of a contract or for the implementation of pre-contractual measures, we process your data on the basis of Art. 6(1)(b) GDPR. Furthermore, if your data is required for the fulfillment of a legal obligation, we process it on the basis of Art. 6(1)(c) GDPR. Furthermore, the data processing may be carried out on the basis of our legitimate interest according to Art. 6(1)(f) GDPR. Information on the relevant legal basis in each individual case is provided in the following paragraphs of this privacy policy. ",
      },
      {
        subtitle: "Designation of a data protection officer",
        subtitleExpl: `We have appointed a data protection officer. 

Cinemo GmbH 
Data Protection Officer 
Kaiserstr. 72 
76133 Karlsruhe 
Germany 

Phone: +49 (0)721 352500 
E-mail: dataprotection@cinemo.com `,
      },
      {
        subtitle:
          "Information on data transfer to the USA and other non-EU countries",
        subtitleExpl:
          "Among other things, we use tools of companies domiciled in the United States or other from a data protection perspective non-secure non-EU countries. If these tools are active, your personal data may potentially be transferred to these non-EU countries and may be processed there. We must point out that in these countries, a data protection level that is comparable to that in the EU cannot be guaranteed. For instance, U.S. enterprises are under a mandate to release personal data to the security agencies and you as the data subject do not have any litigation options to defend yourself in court. Hence, it cannot be ruled out that U.S. agencies (e.g., the Secret Service) may process, analyze, and permanently archive your personal data for surveillance purposes. We have no control over these processing activities. ",
      },
      {
        subtitle: "Recipients of personal data",
        subtitleExpl:
          "In the scope of our business activities, we cooperate with various external parties. In some cases, this also requires the transfer of personal data to these external parties. We only disclose personal data to external parties if this is required as part of the fulfillment of a contract, if we are legally obligated to do so (e.g., disclosure of data to tax authorities), if we have a legitimate interest in the disclosure pursuant to Art. 6(1)(f) GDPR, or if another legal basis permits the disclosure of this data. When using processors, we only disclose personal data of our customers on the basis of a valid contract on data processing. In the case of joint processing, a joint processing agreement is concluded.",
      },

      {
        subtitle: "Revocation of your consent to the processing of data",
        subtitleExpl:
          "A wide range of data processing transactions are possible only subject to your express consent. You can also revoke at any time any consent you have already given us. This shall be without prejudice to the lawfulness of any data collection that occurred prior to your revocation.",
      },
      {
        subtitle: "Right to object to data collection in special cases",

        subtitleExpl: `IN THE EVENT THAT DATA ARE PROCESSED ON THE BASIS OF ART. 6(1)(E) OR (F) GDPR, YOU HAVE THE RIGHT TO AT ANY TIME OBJECT TO THE PROCESSING OF YOUR PERSONAL DATA BASED ON GROUNDS ARISING FROM YOUR UNIQUE SITUATION. THIS ALSO APPLIES TO ANY PROFILING BASED ON THESE PROVISIONS. TO DETERMINE THE LEGAL BASIS, ON WHICH ANY PROCESSING OF DATA IS BASED, PLEASE CONSULT THIS DATA PROTECTION DECLARATION. IF YOU LOG AN OBJECTION, WE WILL NO LONGER PROCESS YOUR AFFECTED PERSONAL DATA, UNLESS WE ARE IN A POSITION TO PRESENT COMPELLING PROTECTION WORTHY GROUNDS FOR THE PROCESSING OF YOUR DATA, THAT OUTWEIGH YOUR INTERESTS, RIGHTS AND FREEDOMS OR IF THE PURPOSE OF THE PROCESSING IS THE CLAIMING, EXERCISING OR DEFENCE OF LEGAL ENTITLEMENTS (OBJECTION PURSUANT TO ART. 21(1) GDPR). 

IF YOUR PERSONAL DATA IS BEING PROCESSED IN ORDER TO ENGAGE IN DIRECT ADVERTISING, YOU HAVE THE RIGHT TO OBJECT TO THE PROCESSING OF YOUR AFFECTED PERSONAL DATA FOR THE PURPOSES OF SUCH ADVERTISING AT ANY TIME. THIS ALSO APPLIES TO PROFILING TO THE EXTENT THAT IT IS AFFILIATED WITH SUCH DIRECT ADVERTISING. IF YOU OBJECT, YOUR PERSONAL DATA WILL SUBSEQUENTLY NO LONGER BE USED FOR DIRECT ADVERTISING PURPOSES (OBJECTION PURSUANT TO ART. 21(2) GDPR). `,
      },
      {
        subtitle:
          "Right to log a complaint with the competent supervisory agency ",
        subtitleExpl:
          "In the event of violations of the GDPR, data subjects are entitled to log a complaint with a supervisory agency, in particular in the member state where they usually maintain their domicile, place of work or at the place where the alleged violation occurred. The right to log a complaint is in effect regardless of any other administrative or court proceedings available as legal recourses.",
      },
      {
        subtitle: "Right to data portability ",
        subtitleExpl: `You have the right to demand that we hand over any data we automatically process on the basis of your consent or in order to fulfil a contract be handed over to you or a third party in a commonly used, machine readable format. If you should demand the direct transfer of the data to another controller, this will be done only if it is technically feasible. `,
      },
      {
        subtitle: "Information about, rectification and eradication of data ",
        subtitleExpl: `Within the scope of the applicable statutory provisions, you have the right to at any time demand information about your archived personal data, their source and recipients as well as the purpose of the processing of your data. You may also have a right to have your data rectified or eradicated. If you have questions about this subject matter or any other questions about personal data, please do not hesitate to contact us at any time. `,
      },
      {
        subtitle: "Right to demand processing restrictions ",
        subtitleExpl: `You have the right to demand the imposition of restrictions as far as the processing of your personal data is concerned. To do so, you may contact us at any time. The right to demand restriction of processing applies in the following cases:

      - In the event that you should dispute the correctness of your data archived by us, we will usually need some time to verify this claim. During the time that this investigation is ongoing, you have the right to demand that we restrict the processing of your personal data.
      
      - If the processing of your personal data was/is conducted in an unlawful manner, you have the option to demand the restriction of the processing of your data in lieu of demanding the eradication of this data.
      
      - If we do not need your personal data any longer and you need it to exercise, defend or claim legal entitlements, you have the right to demand the restriction of the processing of your personal data instead of its eradication.
      
      - If you have raised an objection pursuant to Art. 21(1) GDPR, your rights and our rights will have to be weighed against each other. As long as it has not been determined whose interests prevail, you have the right to demand a restriction of the processing of your personal data.
      
      If you have restricted the processing of your personal data, these data – with the exception of their archiving – may be processed only subject to your consent or to claim, exercise or defend legal entitlements or to protect the rights of other natural persons or legal entities or for important public interest reasons cited by the European Union or a member state of the EU.      `,
      },
      {
        subtitle: "SSL and/or TLS encryption ",
        subtitleExpl: `For security reasons and to protect the transmission of confidential content, such as purchase orders or inquiries you submit to us as the website operator, this website uses either an SSL or a TLS encryption program. You can recognize an encrypted connection by checking whether the address line of the browser switches from “http://” to “https://” and also by the appearance of the lock icon in the browser line. 

If the SSL or TLS encryption is activated, data you transmit to us cannot be read by third parties. `,
      },
      {
        subtitle: "Rejection of unsolicited e-mails ",
        subtitleExpl:
          "We herewith object to the use of contact information published in conjunction with the mandatory information to be provided in our Site Notice to send us promotional and information material that we have not expressly requested. The operators of this website and its pages reserve the express right to take legal action in the event of the unsolicited sending of promotional information, for instance via SPAM messages. ",
      },
    ],
  },
  {
    title: "4. Recording of Data on This Website",
    content: [
      {
        subtitle: "Cookies",
        subtitleExpl: `Our website uses consent technology from CookieYes to obtain your consent to the storage of certain cookies on your end device or for the use of certain technologies and to document this in a data protection-compliant manner. Our website uses the cookie consent technology of CookieYes to obtain your consent to the storage of certain cookies in your browser and to document this in a data protection-compliant manner. The provider of this technology is 

CookieYes Limited, (hereinafter “CookieYes”) 
3 Warren Yard Warren Park 
Wolverton Mill, Milton Keyn MK12 5NW, 
United Kingdom. `,
      },
      {
        subtitle: "What are cookies?",
        subtitleExpl:
          "Cookies are small text files that are used to store small pieces of information. They are stored on your device when the website is loaded on your browser. These cookies help us make the website function properly, make it more secure, provide better user experience, and understand how the website performs and to analyze what works and where it needs improvement.",
      },
      {
        subtitle: "How do we use cookies?",

        subtitleExpl:
          "As most of the online services, our website uses first-party and third-party cookies for several purposes. First-party cookies are mostly necessary for the website to function the right way, and they do not collect any of your personally identifiable data. The third-party cookies used on our website are mainly for understanding how the website performs, how you interact with our website, keeping our services secure, providing advertisements that are relevant to you, and all in all providing you with a better and improved user experience and help speed up your future interactions with our website.",
      },
      {
        subtitle: "Consent with CookieYes",
        nestedContent: [
          {
            subSubtitle: "Necessary",
            subSubtitleExpl:
              "Necessary cookies are required to enable the basic features of this site, such as providing secure log-in or adjusting your consent preferences. These cookies do not store any personally identifiable data.",
            table: {
              headers: ["Cookie", "Duration", "Description"],
              rows: [
                {
                  Cookie: "cookieyes-consent",
                  Duration: "1 year",
                  Description:
                    "CookieYes sets this cookie to remember users’ consent preferences so that their preferences are respected on their subsequent visits to this site. It does not collect or store any personal information of the site visitors.",
                },
              ],
            },
          },
          {
            subSubtitle: "Analytics",
            subSubtitleExpl:
              "Analytical cookies are used to understand how visitors interact with the website. These cookies help provide information on metrics such as the number of visitors, bounce rate, traffic source, etc.",
            table: {
              headers: ["Cookie", "Duration", "Description"],
              rows: [
                {
                  Cookie: "_ga",
                  Duration: "1 year 1 month 4 days",
                  Description:
                    "The _ga cookie, installed by Google Analytics, calculates visitor, session and campaign data and also keeps track of site usage for the site’s analytics report. The cookie stores information anonymously and assigns a randomly generated number to recognize unique visitors.",
                },
                {
                  Cookie: "_hjTLDTest",
                  Duration: "Session",
                  Description:
                    "To determine the most generic cookie path that has to be used instead of the page hostname, Hotjar sets the _hjTLDTest cookie to store different URL substring alternatives until it fails.",
                },
                {
                  Cookie: "_ga_*",
                  Duration: "1 year 1 month 4 days",
                  Description:
                    "Google Analytics sets this cookie to store and count page views.",
                },
                {
                  Cookie: "_hjSessionUser_*",
                  Duration: "1 year",
                  Description:
                    "Hotjar sets this cookie to ensure data from subsequent visits to the same site are attributed to the same user ID, which persists in the Hotjar User ID, which is unique to that site.",
                },
                {
                  Cookie: "_hjSession_*",
                  Duration: "1 hour",
                  Description:
                    "Hotjar sets this cookie to ensure data from subsequent visits to the same site are attributed to the same user ID, which persists in the Hotjar User ID, which is unique to that site.",
                },
              ],
            },
          },
        ],
      },
      {
        subtitle: "Manage CookieYes Preferences",
        subtitleExpl: `Cookie Settings 

You can change your cookie preferences any time by clicking the above link “Cookie Settings”. This will let you revisit the cookie consent banner and change your preferences or withdraw your consent right away. 

In addition to this, different browsers provide different methods to block and delete cookies used by websites. You can change the settings of your browser to block/delete the cookies. Listed below are the links to the support documents on how to manage and delete cookies from the major web browsers. 

Chrome: <a href="https://support.google.com/accounts/answer/32050" target="_blank" rel="noopener noreferrer">https://support.google.com/accounts/answer/32050</a>

Safari: <a href="https://support.apple.com/en-in/guide/safari/sfri11471/mac" target="_blank" rel="noopener noreferrer">https://support.apple.com/en-in/guide/safari/sfri11471/mac </a>

Firefox: <a href="https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox?redirectslug=delete-cookies-remove-info-websites-stored&redirectlocale=en-US" target="_blank" rel="noopener noreferrer">https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox?redirectslug=delete-cookies-remove-info-websites-stored&redirectlocale=en-US </a>

Internet Explorer: <a href="https://support.microsoft.com/en-us/topic/how-to-delete-cookie-files-in-internet-explorer-bca9446f-d873-78de-77ba-d42645fa52fc" target="_blank" rel="noopener noreferrer">https://support.microsoft.com/en-us/topic/how-to-delete-cookie-files-in-internet-explorer-bca9446f-d873-78de-77ba-d42645fa52fc </a>

If you are using any other web browser, please visit your browser’s official support documents. 

You have the option to set up your browser in such a manner that you will be notified any time cookies are placed and to permit the acceptance of cookies only in specific cases. You may also exclude the acceptance of cookies in certain cases or in general or activate the delete function for the automatic eradication of cookies when the browser closes. If cookies are deactivated, the functions of this website may be limited. 

In the event that third-party cookies are used or if cookies are used for analytical purposes, we will separately notify you in conjunction with this Data Protection Policy and, if applicable, ask for your consent. `,
      },
      {
        subtitle: "Server log files",
        subtitleExpl: `The provider of this website and its pages automatically collects and stores information in so-called server log files, which your browser communicates to us automatically. The information comprises:

- The type and version of browser used
- The used operating system
- Referrer URL
- The hostname of the accessing computer
- The time of the server inquiry
- The IP address

This data is not merged with other data sources.

This data is recorded on the basis of Art. 6(1)(f) GDPR. The operator of the website has a legitimate interest in the technically error-free depiction and the optimization of the operator’s website. In order to achieve this, server log files must be recorded.`,
      },
      {
        subtitle: "Contact form",
        subtitleExpl: `If you submit inquiries to us via our contact form, the information provided in the contact form as well as any contact information provided therein will be stored by us in order to handle your inquiry and in the event that we have further questions. We will not share this information without your consent. 

The processing of these data is based on Art. 6(1)(b) GDPR, if your request is related to the execution of a contract or if it is necessary to carry out pre-contractual measures. In all other cases the processing is based on our legitimate interest in the effective processing of the requests addressed to us (Art. 6(1)(f) GDPR) or on your agreement (Art. 6(1)(a) GDPR) if this has been requested; the consent can be revoked at any time. 

The information you have entered into the contact form shall remain with us until you ask us to eradicate the data, revoke your consent to the archiving of data or if the purpose for which the information is being archived no longer exists (e.g., after we have concluded our response to your inquiry). This shall be without prejudice to any mandatory legal provisions, in particular retention periods. `,
      },
      {
        subtitle: "Request by e-mail, telephone, or fax ",
        subtitleExpl: `If you contact us by e-mail, telephone or fax, your request, including all resulting personal data (name, request) will be stored and processed by us for the purpose of processing your request. We do not pass these data on without your consent. 

These data are processed on the basis of Art. 6(1)(b) GDPR if your inquiry is related to the fulfillment of a contract or is required for the performance of pre-contractual measures. In all other cases, the data are processed on the basis of our legitimate interest in the effective handling of inquiries submitted to us (Art. 6(1)(f) GDPR) or on the basis of your consent (Art. 6(1)(a) GDPR) if it has been obtained; the consent can be revoked at any time. 

The data sent by you to us via contact requests remain with us until you request us to delete, revoke your consent to the storage or the purpose for the data storage lapses (e.g. after completion of your request). Mandatory statutory provisions – in particular statutory retention periods – remain unaffected. `,
      },
      {
        subtitle: "Calendly",
        subtitleExpl: `We use the “Calendly” tool for booking appointments. The provider of the booking tool is Calendly LLC, 271 17th St NW, 10th Floor, Atlanta, Georgia 30363, USA (hereinafter “Calendly”). 

When accessing some sub-services of our website, additional personal services are processed. Processed data categories: contact information like Name and e-mail address. Purpose of processing: display and selection of appointments. The legal basis for processing: the need to fulfill an agreement or pre-contractual measures at your request (Article 6 (1) b GDPR).  

Data is transmitted: to the data processor Calendly, Inc., 115 E Main St., Ste. A1B, Bufort, GA 30518, USA represented by DPO Centre Europe, Friedrichstraße 88, 10117 Berlin, Germany, eurep@calendly.com. This may also mean a transfer of personal data to a country outside the European Union. The data is transferred to the USA on the basis of Art. 45 GDPR in conjunction with the European Commission's adequacy decision C(2023) 4745, since the data recipient has committed to comply with the data processing principles of the Data Privacy Framework (DPF). `,
      },
    ],
  },
  {
    title: "5. Analysis Tools and Advertising",
    content: [
      {
        subtitle: "Google Tag Manager",
        subtitleExpl: `We use the Google Tag Manager. The provider is Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Ireland 

The Google Tag Manager is a tool that allows us to integrate tracking or statistical tools and other technologies on our website. The Google Tag Manager itself does not create any user profiles, does not store cookies, and does not carry out any independent analyses. It only manages and runs the tools integrated via it. However, the Google Tag Manager does collect your IP address, which may also be transferred to Google’s parent company in the United States. 

The Google Tag Manager is used on the basis of Art. 6(1)(f) GDPR. The website operator has a legitimate interest in the quick and uncomplicated integration and administration of various tools on his website. If appropriate consent has been obtained, the processing is carried out exclusively on the basis of Art. 6(1)(a) GDPR and § 25 (1) TDDDG, insofar the consent includes the storage of cookies or the access to information in the user’s end device (e.g., device fingerprinting) within the meaning of the TDDDG. This consent can be revoked at any time. `,
      },
      {
        subtitle: "Google Analytics",
        subtitleExpl: `This website uses functions of the web analysis service Google Analytics. The provider of this service is Google Ireland Limited (“Google”), Gordon House, Barrow Street, Dublin 4, Ireland. 

Google Analytics enables the website operator to analyze the behavior patterns of website visitors. To that end, the website operator receives a variety of user data, such as pages accessed, time spent on the page, the utilized operating system and the user’s origin. This data is assigned to the respective end device of the user. An assignment to a user-ID does not take place. 

Furthermore, Google Analytics allows us to record your mouse and scroll movements and clicks, among other things. Google Analytics uses various modeling approaches to augment the collected data sets and uses machine learning technologies in data analysis. 

Google Analytics uses technologies that make the recognition of the user for the purpose of analyzing the user behavior patterns (e.g., cookies or device fingerprinting). The website use information recorded by Google is, as a rule transferred to a Google server in the United States, where it is stored. 

The use of these services occurs on the basis of your consent pursuant to Art. 6(1)(a) GDPR and § 25(1) TDDDG. You may revoke your consent at any time. 

Data transmission to the US is based on the Standard Contractual Clauses (SCC) of the European Commission. Details can be found here: <a href="https://privacy.google.com/businesses/controllerterms/mccs/" target="_blank" rel="noopener noreferrer">https://privacy.google.com/businesses/controllerterms/mccs/ </a>

The company is certified in accordance with the “EU-US Data Privacy Framework” (DPF). The DPF is an agreement between the European Union and the US, which is intended to ensure compliance with European data protection standards for data processing in the US. Every company certified under the DPF is obliged to comply with these data protection standards. For more information, please contact the provider under the following link: 

<a href="https://www.dataprivacyframework.gov/participant/5780" target="_blank" rel="noopener noreferrer">https://www.dataprivacyframework.gov/participant/5780. </a>`,
      },
      {
        subtitle: "IP anonymization",
        subtitleExpl:
          "Google Analytics IP anonymization is active. As a result, your IP address will be abbreviated by Google within the member states of the European Union or in other states that have ratified the Convention on the European Economic Area prior to its transmission to the United States. The full IP address will be transmitted to one of Google’s servers in the United States and abbreviated there only in exceptional cases. On behalf of the operator of this website, Google shall use this information to analyze your use of this website to generate reports on website activities and to render other services to the operator of this website that are related to the use of the website and the Internet. The IP address transmitted in conjunction with Google Analytics from your browser shall not be merged with other data in Google’s possession. ",
      },
      {
        subtitle: "Browser plug-in",
        subtitleExpl: `You can prevent the recording and processing of your data by Google by downloading and installing the browser plugin available under the following link:<a href="https://tools.google.com/dlpage/gaoptout?hl=en" target="_blank" rel="noopener noreferrer"> https://tools.google.com/dlpage/gaoptout?hl=en. </a>

For more information about the handling of user data by Google Analytics, please consult Google’s Data Privacy Declaration at: <a href="https://support.google.com/analytics/answer/6004245?hl=en1" target="_blank" rel="noopener noreferrer">https://support.google.com/analytics/answer/6004245?hl=en. </a>`,
      },
      {
        subtitle: "Contract data processing ",
        subtitleExpl:
          "We have executed a contract data processing agreement with Google and are implementing the stringent provisions of the German data protection agencies to the fullest when using Google Analytics. ",
      },
      {
        subtitle: "Hotjar",
        subtitleExpl: `This website utilizes Hotjar. The provider is Hotjar Ltd., Level 2, St Julians Business Centre, 3, Elia Zammit Street, St Julians STJ 1000, Malta, Europe (website: <a href="https://www.hotjar.com" target="_blank" rel="noopener noreferrer">https://www.hotjar.com</a>). 

Hotjar is a tool used to analyze your user patterns on this website. Hotjar allows us to for instance record your mouse and scroll movements as well as your click. During this process, Hotjar also has the capability to determine how long your cursor remained in a certain position. Based on this information, Hotjar compiles so-called Heatmaps, that make possible to determine which parts of the website the website visitor reviews with preference. 

We are also able to determine how long you have stayed on a page of this website and when you left. We can also determine at which point you suspended making entries into a contact form (so-called conversion funnels). 

Furthermore, Hotjar can be deployed to obtain direct feedback from website visitors. This function aims at the improvement of the website offerings of the website operator. 

Hotjar uses technologies that make it possible to recognize the user for the purpose of analyzing the user patterns (e.g., cookies or the deployment of device fingerprinting). 

If your approval (consent) has been obtained the use of the abovementioned service shall occur on the basis of Art. 6(1)(a) GDPR and § 25 TDDDG (German Telecommunications Act). Such consent may be revoked at any time. If your consent was not obtained, the use of the service will occur on the basis of Art. 6(1)(f) GDPR; the website operator has a legitimate interest in the analysis of user patterns to optimize both, the web presentation and the operator’s advertising activities. `,
      },
      {
        subtitle: "Deactivation of Hotjar",
        subtitleExpl: `If you would like to deactivate the recording of data by Hotjar, please click on the link below and follow the instructions provided under the link:<a href="https://www.hotjar.com/policies/do-not-track/" target="_blank" rel="noopener noreferrer"> https://www.hotjar.com/policies/do-not-track/ </a>. 

Please keep in mind that you will have to separately deactivate Hotjar for every browser and every device. 

For more detailed information about Hotjar and the data to be recorded, please consult the Data Privacy Declaration of Hotjar under the following link: <a href="https://www.hotjar.com/privacy" target="_blank" rel="noopener noreferrer">https://www.hotjar.com/privacy </a> `,
      },
      {
        subtitle: "Data processing",
        subtitleExpl:
          "We have concluded a data processing agreement (DPA) for the use of the above-mentioned service. This is a contract mandated by data privacy laws that guarantees that they process personal data of our website visitors only based on our instructions and in compliance with the GDPR. ",
      },
    ],
  },
  {
    title: "6. Plug-Ins and Tools",
    content: [
      {
        subtitle: "Friendly Captcha",
        subtitleExpl: `We use Friendly Captcha (hereinafter referred to as “Friendly Captcha”) on this website. The provider is Friendly Captcha GmbH, Am Anger 3-5, 82237 Woerthsee, Germany. 

Friendly Captcha is used to verify whether the entry of data into this website (e.g., into a contact form) is being processed by a person or an automated program. For this purpose, Friendly Captcha analyzes the behavior patterns of website visitors based on numerous characteristics. For the analysis, Friendly Captcha examines a wide range of information (e.g., anonymized IP address, referrer, time of the visit, etc.). For more related information please visit: <a href="https://friendlycaptcha.com/legal/privacy-end-users/" target="_blank" rel="noopener noreferrer">https://friendlycaptcha.com/legal/privacy-end-users/ </a>. 

The storage and analysis of the data occurs on the basis of Art. 6 (1)(f) GDPR. The website operator has a legitimate interest in protecting the operator’s web presentations against abusive automatic spying and SPAM. In the event that respective consent has been obtained, the data will be processed exclusively on the basis of Art. 6 (1)(a) GDPR and § 25 (1) TDDDG, if the consent comprises the storage of cookies or access to information on the user’s device (e.g., device fingerprinting) as defined in the TDDDG (German Telecommunications Act). Such consent may be revoked at any time. `,
      },
      {
        subtitle: "DocuSign – Contract Executions and Management",
        subtitleExpl: `The following additional information applies when using DocuSign products. 

DocuSign is a processor within the meaning of Art. 4 No. 8 GDPR. The contract details in the sense of Art. 28 GDPR are available online at: <a href="https://www.docusign.com/legal/terms-and-conditions/data-protection-attachment/" target="_blank" rel="noopener noreferrer">https://www.docusign.com/legal/terms-and-conditions/data-protection-attachment/ </a>

The controller of the data is our organisation. 

The data is used for the provision of services, contract management and/or invoicing. (Article 6 I b) GDPR) We collect and process the following data: 

Contract data: This is the data that you provide to our organisation for the performance of the agreed services as part of the order placement or any subsequent, direct contact, such as: Name, address, telephone number and e-mail address. In addition, information about payment processing and invoice data. 

Our organisation generally processes your data in Germany. Only in exceptional cases will the data be processed in other European countries. This is done within the legally prescribed framework on the basis of regulations that guarantee a secure level of data protection (e.g. EU standard contractual clauses or specific consent for exceptional cases). 

Your data will be deleted in accordance with the statutory retention periods. In the case of contracts, data may be stored for up to 10 years after termination of the contractual relationship within the meaning of sec. § 147 AO `,
      },
    ],
  },
  {
    title: "7. Online-Based Audio and Video Conferences (Conference Tools)",
    content: [
      {
        subtitle: "Data Processing",
        subtitleExpl: `We use online conference tools, among other things, for communication with our customers. The tools we use are listed in detail below. If you communicate with us by video or audio conference using the Internet, your personal data will be collected and processed by the provider of the respective conference tool and by us. The conferencing tools collect all information that you provide/access to use the tools (email address and/or your phone number). Furthermore, the conference tools process the duration of the conference, start and end (time) of participation in the conference, number of participants and other “context information” related to the communication process (metadata). 

Furthermore, the provider of the tool processes all the technical data required for the processing of the online communication. This includes, in particular, IP addresses, MAC addresses, device IDs, device type, operating system type and version, client version, camera type, microphone or loudspeaker and the type of connection. 

Should content be exchanged, uploaded, or otherwise made available within the tool, it is also stored on the servers of the tool provider. Such content includes, but is not limited to, cloud recordings, chat/ instant messages, voicemail uploaded photos and videos, files, whiteboards, and other information shared while using the service. 

Please note that we do not have complete influence on the data processing procedures of the tools used. Our possibilities are largely determined by the corporate policy of the respective provider. Further information on data processing by the conference tools can be found in the data protection declarations of the tools used, and which we have listed below this text. `,
      },
      {
        subtitle: "Purpose and legal bases",
        subtitleExpl: `The conference tools are used to communicate with prospective or existing contractual partners or to offer certain services to our customers (Art. 6(1)(b) GDPR). Furthermore, the use of the tools serves to generally simplify and accelerate communication with us or our company (legitimate interest in the meaning of Art. 6(1)(f) GDPR). Insofar as consent has been requested, the tools in question will be used on the basis of this consent; the consent may be revoked at any time with effect from that date.

Data collected directly by us via the video and conference tools will be deleted from our systems immediately after you request us to delete it, revoke your consent to storage, or the reason for storing the data no longer applies. Stored cookies remain on your end device until you delete them. Mandatory legal retention periods remain unaffected. 

We have no influence on the duration of storage of your data that is stored by the operators of the conference tools for their own purposes. For details, please directly contact the operators of the conference tools.  `,
      },
      {
        subtitle: "Conference Tools Used",
        nestedContent: [
          {
            subSubtitle: "Microsoft Teams",
            subSubtitleExpl: `We use Microsoft Teams. The provider is the Microsoft Ireland Operations Limited, One Microsoft Place, South County Business Park, Leopardstown, Dublin 18, Ireland. For details on data processing, please refer to the Microsoft Teams privacy policy: 

<a href="https://privacy.microsoft.com/en-us/privacystatement" target="_blank" rel="noopener noreferrer">https://privacy.microsoft.com/en-us/privacystatement </a>

The company is certified in accordance with the “EU-US Data Privacy Framework” (DPF). The DPF is an agreement between the European Union and the US, which is intended to ensure compliance with European data protection standards for data processing in the US. Every company certified under the DPF is obliged to comply with these data protection standards. For more information, please contact the provider under the following link: 

<a href="https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt0000000KzNaAAK&status=Active " target="_blank" rel="noopener noreferrer">https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt0000000KzNaAAK&status=Active </a> `,
          },
          {
            subSubtitle: "Zoom",
            subSubtitleExpl: `We use Zoom. The provider of this service is Zoom Communications Inc, San Jose, 55 Almaden Boulevard, 6th Floor, San Jose, CA 95113, USA. For details on data processing, please refer to Zoom’s privacy policy: 

<a href="https://explore.zoom.us/en/privacy/" target="_blank" rel="noopener noreferrer"> https://explore.zoom.us/en/privacy/ </a>

Data transmission to the US is based on the Standard Contractual Clauses (SCC) of the European Commission. Details can be found here: 


<a href="https://explore.zoom.us/en/privacy/ " target="_blank" rel="noopener noreferrer"> https://explore.zoom.us/en/privacy/ </a>`,
          },
        ],
      },
    ],
  },
  {
    title: "8. Custom Services",
    content: [
      {
        subtitle: "Handling Applicant Data",
        subtitleExpl:
          "We offer website visitors the opportunity to submit job applications to us (e.g., via e-mail, via postal services on by submitting the online job application form). Below, we will brief you on the scope, purpose and use of the personal data collected from you in conjunction with the application process. We assure you that the collection, processing, and use of your data will occur in compliance with the applicable data privacy rights and all other statutory provisions and that your data will always be treated as strictly confidential. ",
      },
      {
        subtitle: "Scope and purpose of the collection of data ",
        subtitleExpl: `If you submit a job application to us, we will process any affiliated personal data (e.g., contact and communications data, application documents, notes taken during job interviews, etc.), if they are required to make a decision concerning the establishment or an employment relationship. The legal grounds for the aforementioned are § 26 BDSG according to German Law (Negotiation of an Employment Relationship), Art. 6(1)(b) GDPR (General Contract Negotiations) and – provided you have given us your consent – Art. 6(1)(a) GDPR. You may revoke any consent given at any time. Within our company, your personal data will only be shared with individuals who are involved in the processing of your job application. 

If your job application should result in your recruitment, the data you have submitted will be archived on the grounds of § 26 BDSG and Art. 6(1)(b) GDPR for the purpose of implementing the employment relationship in our data processing system. `,
      },
      {
        subtitle: "Data Archiving Period ",
        subtitleExpl: `If we are unable to make you a job offer or you reject a job offer or withdraw your application, we reserve the right to retain the data you have submitted on the basis of our legitimate interests (Art. 6(1)(f) GDPR) for up to 6 months from the end of the application procedure (rejection or withdrawal of the application). Afterwards the data will be deleted, and the physical application documents will be destroyed. The storage serves in particular as evidence in the event of a legal dispute. If it is evident that the data will be required after the expiry of the 6-month period (e.g., due to an impending or pending legal dispute), deletion will only take place when the purpose for further storage no longer applies. 

Longer storage may also take place if you have given your agreement (Article 6(1)(a) GDPR) or if statutory data retention requirements preclude the deletion. `,
      },
      {
        subtitle: "Admission to the applicant pool ",
        subtitleExpl: `If we do not make you a job offer, you may be able to join our applicant pool. In case of admission, all documents and information from the application will be transferred to the applicant pool in order to contact you in case of suitable vacancies. 

Admission to the applicant pool is based exclusively on your express agreement (Art. 6(1)(a) GDPR). The submission agreement is voluntary and has no relation to the ongoing application procedure. The affected person can revoke his agreement at any time. In this case, the data from the applicant pool will be irrevocably deleted, provided there are no legal reasons for storage. 

The data from the applicant pool will be irrevocably deleted no later than two years after consent has been granted. `,
      },
      {
        subtitle: "Use of CAREER Page",
        subtitleExpl: `We use the applicant platform Personio (<a href="https://www.personio.de " target="_blank" rel="noopener noreferrer">https://www.personio.de </a>), which is operated by 

Personio SE & Co. KG 
Seidlstraße 3 
80335 München 
Germany 

It is a platform through which job applications are made possible. When you access the careers page of our website, the link embedded there establishes a connection to Personio to enable the technical transfer of the application documents. When establishing the connection to Personio, data is transferred to Personio. The purpose and scope of the data collection, the further processing and use of the data by Personio as well as Cinemo GmbH serves the application management. The digital applicant file contains all data of the candidate as well as the entire contact history. When you visit the Personio web pages, Personio stores by default the IP address assigned to you by your Internet service provider, the web page from which you visit us, the web pages you visit on our site, and the date and duration of your visit. Personal data that we need exclusively to process your application will only be stored if you provide it to us of your own accord as part of your application. Your personal information and data will be collected, stored and used only for the intended purpose with the utmost care and integrity. The legal provisions of the Data Protection Basic Regulation and the Federal Data Protection Act are observed. We only collect data that is necessary in the course of your application to Cinemo GmbH. You agree to the processing and transmission of your data exclusively for the application process. You assure that the information you provide is true. You are also aware that untrue information can lead to the termination of a possible employment relationship. `,
      },
      {
        subtitle: "Your online application ",
        subtitleExpl: `After entering and submitting your data, it will be sent directly to the server of our external service provider Personio via an encrypted connection. All data is encrypted on the basis of the SSL procedure. If you log in with the provided user data after your registration, the SSL encryption procedure will also be used for this. For more information on how Personio processes personal data, please refer to the privacy policy at <a href="https://www.personio.com/privacy-policy/ " target="_blank" rel="noopener noreferrer">https://www.personio.com/privacy-policy/ </a> `,
      },
      {
        subtitle: "Jobalert – Application ",
        subtitleExpl:
          "You will be automatically notified by e-mail as soon as a new job offer appears that matches your search specifications. Your e-mail address will only be used for this service and will not be passed on to third parties. You can unsubscribe from the Jobalert at any time with just one click. By clicking on the Jobalert button, you provide us with your e-mail address via the external service provider Personio. You will receive a confirmation message with the corresponding link to the application. The confirmation message also contains a link to unsubscribe from the Jobalert service. Afterwards the given mail address will be deleted. ",
      },
      {
        subtitle: "Data security for online application ",
        subtitleExpl:
          "Your data will be processed by Personio within the framework of order processing in accordance with Art. 28 of the Basic Data Protection Regulation. Both Cinemo GmbH and Personio use technical and organizational security measures to protect your collected data against accidental or intentional manipulation, loss, destruction or against access by unauthorized persons. Our security measures are constantly being improved in line with technological developments. ",
      },
    ],
  },
];

/* 
 {
                subSubtitle: "Necessary",
                subSubtitleExpl:
                  "Necessary cookies are required to enable the basic features of this site, such as providing secure log-in or adjusting your consent preferences. These cookies do not store any personally identifiable data.",
                table: {
                  headers: ["Cookie", "Duration", "Description"],
                  rows: [
                    {
                      Cookie: "cookieyes-consent",
                      Duration: "1 year",
                      Description:
                        "CookieYes sets this cookie to remember users’ consent preferences so that their preferences are respected on their subsequent visits to this site. It does not collect or store any personal information of the site visitors.",
                    },
                  ],
                },
              },
              {
                subSubtitle: "Analytics",
                subSubtitleExpl:
                  "Analytical cookies are used to understand how visitors interact with the website. These cookies help provide information on metrics such as the number of visitors, bounce rate, traffic source, etc.",
                table: {
                  headers: ["Cookie", "Duration", "Description"],
                  rows: [
                    {
                      Cookie: "_ga",
                      Duration: "1 year 1 month 4 days",
                      Description:
                        "The _ga cookie, installed by Google Analytics, calculates visitor, session and campaign data and also keeps track of site usage for the site’s analytics report. The cookie stores information anonymously and assigns a randomly generated number to recognize unique visitors.",
                    },
                    {
                      Cookie: "_hjTLDTest",
                      Duration: "Session",
                      Description:
                        "To determine the most generic cookie path that has to be used instead of the page hostname, Hotjar sets the _hjTLDTest cookie to store different URL substring alternatives until it fails.",
                    },
                    {
                      Cookie: "_ga_*",
                      Duration: "1 year 1 month 4 days",
                      Description:
                        "Google Analytics sets this cookie to store and count page views.",
                    },
                    {
                      Cookie: "_hjSessionUser_*",
                      Duration: "1 year",
                      Description:
                        "Hotjar sets this cookie to ensure data from subsequent visits to the same site are attributed to the same user ID, which persists in the Hotjar User ID, which is unique to that site.",
                    },
                    {
                      Cookie: "_hjSession_*",
                      Duration: "1 hour",
                      Description:
                        "Hotjar sets this cookie to ensure data from subsequent visits to the same site are attributed to the same user ID, which persists in the Hotjar User ID, which is unique to that site.",
                    },
                  ],
                },
              }, 
              */
