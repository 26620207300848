import React from "react";
import styles from "./styles/Legals.module.css";
import { PrivacyPolicyData } from "./PrivacyPolicyText";
import Navbar from "./components/Navbar";

const PrivacyPolicy: React.FC = () => {
  console.log(PrivacyPolicyData);

  return (
    <>
      <Navbar isItNavbar={true} legalPage={true} />
      <div className={styles.legalsContainer}>
        <h1>Privacy Policy</h1>
        <div className={styles.legalsContent}>
          {PrivacyPolicyData.map((section, sectionIndex) => (
            <div key={sectionIndex} className={styles.section}>
              {/* Render section title */}
              <h2 className={styles.sectionTitle}>{section.title}</h2>

              {section.content.map((item, itemIndex) => (
                <div key={itemIndex} className={styles.subtitleContainer}>
                  {/* Render subtitle */}
                  <h3 className={styles.subtitle}>{item.subtitle}</h3>

                  {/* Render subtitle explanation */}
                  {"subtitleExpl" in item && item.subtitleExpl && (
                    <p
                    className={styles.subtitleExpl}
                    dangerouslySetInnerHTML={{ __html: item.subtitleExpl }}
                  ></p>

                  
                  )}

                  {/* Render nested content */}
                  {"nestedContent" in item &&
                    item.nestedContent &&
                    item.nestedContent.map((nested, nestedIndex) => (
                      <div key={nestedIndex} className={styles.nestedContainer}>
                        {/* Render nested subtitles */}
                        <h4 className={styles.subSubtitle}>
                          {nested.subSubtitle}
                        </h4>

                        {/* Render nested subtitle explanations */}
                        {"subSubtitleExpl" in nested &&
                          nested.subSubtitleExpl && (
                             <p
                    className={styles.subtitleExpl}
                    dangerouslySetInnerHTML={{ __html: nested.subSubtitleExpl }}
                  ></p>
                          )}

                        {/* Render table if present */}
                        {"table" in nested && nested.table && (
  <div className={styles.tableContainer}>
    <table className={styles.table}>
      <thead>
        <tr>
          {nested.table.headers.map((header, headerIndex) => (
            <th key={headerIndex}>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {nested.table.rows.map((row, rowIndex) => (
          <tr key={rowIndex}>
            {Object.values(row).map((cell, cellIndex) => (
              <td key={cellIndex}>{cell}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  </div>
)}


                        {/* Render additional nested content recursively */}
                        {"nestedContent" in nested &&
                          nested.nestedContent &&
                          nested.nestedContent.map(
                            (innerNested, innerNestedIndex) => (
                              <div
                                key={innerNestedIndex}
                                className={styles.innerNestedContainer}
                              >
                                {/* Render inner nested subtitles */}
                                <h5 className={styles.innerSubSubtitle}>
                                  {innerNested.subSubtitle}
                                </h5>

                                {/* Render inner nested subtitle explanations */}
                                {"subSubtitleExpl" in innerNested &&
                                  innerNested.subSubtitleExpl && (
                                    <p className={styles.innerSubSubtitleExpl}>
                                      {innerNested.subSubtitleExpl}
                                    </p>
                                  )}

                                {/* Render table if present in inner nested */}
                                {"table" in innerNested &&
                                  innerNested.table && (
                                    <div className={styles.tableContainer}>
                                      <table className={styles.table}>
                                        <thead>
                                          <tr>
                                            {innerNested.table.headers.map(
                                              (header, headerIndex) => (
                                                <th key={headerIndex}>
                                                  {header}
                                                </th>
                                              )
                                            )}
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {innerNested.table.rows.map(
                                            (row, rowIndex) => (
                                              <tr key={rowIndex}>
                                                {Object.values(row).map(
                                                  (cell, cellIndex) => (
                                                    <td key={cellIndex}>
                                                      {cell}
                                                    </td>
                                                  )
                                                )}
                                              </tr>
                                            )
                                          )}
                                        </tbody>
                                      </table>
                                    </div>
                                  )}
                              </div>
                            )
                          )}
                      </div>
                    ))}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
      <Navbar isItNavbar={false} legalPage={true} />
    </>
  );
};

export default PrivacyPolicy;
