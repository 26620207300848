import React from "react";
import styles from "./styles/Introduction.module.css";
import CESLogo from "./Assets/CESLogo.png";
import CustomButton from "./components/CustomButton";
const Introduction: React.FC = () => {
  return (
    <div className={styles.introductionContainer}>
      <div className={styles.introduction}>
        <div className={styles.slogan}>
          <h1>
            Your Digital Content. <br /> Anytime.
            <br /> Anywhere.
          </h1>
        </div>
        <div className={styles.logo}>
          <img src={CESLogo} alt="CES Logo"/>
        </div>
      </div>
      <div className={styles.introductionActions}>
        <h2>
          Discover how to create seamless digital media experiences with Cinemo.
          <br/>Join us at CES 2025 - January 7-10, Las Vegas
        </h2>
        <CustomButton/>
      </div>
    </div>
  );
};
export default Introduction;
