import React from "react";

import Navbar from "./components/Navbar";
import Introduction from "./Introduction";
import Table from "./Table";
import Finish from "./Finish";
import Imprint from "./Imprint";
import PrivacyPolicy from "./PrivacyPolicy";
const LandingPage: React.FC = () => {
  return (
    <>
    <Navbar isItNavbar={true} legalPage={false}/>
      <Introduction />
      <Table />
      <Finish />
      <Navbar isItNavbar={false} legalPage={false}/>

    </>
  );
};
export default LandingPage;
