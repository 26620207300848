import React from "react";
import styles from "./styles/Finish.module.css";
import CustomButton from "./components/CustomButton";
import DirectionsSVG from "./Assets/Directions - Westgate.svg";
import Directions from "./Assets/Directions.svg";
import Directionsv2 from "./Assets/Directionsv2.svg";
const Finish: React.FC = () => {

  return (
    <div className={styles.finishContainer}>
      <div className={styles.cinemoDirections}>
        <img src={Directionsv2} alt="Directions" />
      </div>
      <div className={styles.endContent}>
        <h3>
          We offer limousine pick up and drop off service to our suite during
          the show. <br /> Please reserve this service when booking your demo.
        </h3>
        <CustomButton />
      </div>
    </div>
  );
};
export default Finish;
