import React from "react";
import styles from "./styles/Table.module.css";
import DEVICEPNG from "./Assets/Device.png";
const Table: React.FC = () => {
  return (
    <div className={styles.tableContainer}>
      <div className={styles.tableContent}>
        <div className={styles.deviceMakers}>
          <h4>Get advanced digital media features.</h4>
          <ul>
            <li>
            Integrate synchronized playback and multi-room without development efforts.
            </li>
            <li>
              One integration – all features, even after device production.
            </li>
            <li>Discover our Partner Portal, built for easy collaboration.</li>
           
          </ul>
        </div>
        <div className={styles.contentProviders}>
          <img src={DEVICEPNG}/>
        </div>
      </div>
      <h3>
        Cinemo is the first open cloud ecosystem that connects users, device makers,
        <br />
        and content service providers.
      </h3>
    </div>
  );
};
export default Table;
