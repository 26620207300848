import React from "react";
import styles from "../styles/CustomButton.module.css";
const CustomButton: React.FC = () => {
  return (
    <button className={styles.customButtonContainer}>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://calendly.com/cinemo_ecosytem/demo-at-ces-2025?preview_source=et_card&month=2025-01"
      >
        BOOK YOUR DEMO
      </a>
    </button>
  );
};
export default CustomButton;
