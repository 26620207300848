import React, { useState, useEffect } from "react";
import styles from "../styles/Navbar.module.css";
import Logo from "../Assets/CinemoLogoLong.png";
import LogoSVG from "../Assets/CinemoLogoSVG.svg";
import CustomButton from "./CustomButton";
import { NavbarFooterProps } from "../globalTypes";
import { Link, Navigate, useNavigate } from "react-router-dom";

const Navbar: React.FC<NavbarFooterProps> = ({ isItNavbar, legalPage }) => {
  const navigate = useNavigate();
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 800);
  
  useEffect(() => {
    const handleResize = () => setIsDesktop(window.innerWidth > 800);

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className={styles.navbarContainer}>
      <div className={styles.navbarLogo}>
        <img src={LogoSVG} alt="Cinemo Logo" onClick={() => navigate("/")}/>

        {isItNavbar || (
          <div>
            {/* <h2>ecosystem@cinemo.com</h2> */}
            <h2>© Copyright Cinemo 2024</h2>
            <div className={styles.legal}>
              <a href="/imprint">Imprint</a>
              <a href="/privacy-policy">Privacy Policy</a>
            </div>
          </div>
        )}
      </div>
      {!legalPage && isItNavbar && isDesktop && <CustomButton />}

      {!legalPage && !isItNavbar && (
        <div>
          <h2>
            <a
              href="mailto:ecosystem@cinemo.com"
              style={{ textDecoration: "none", color: "inherit" }}
              target="_top"
            >
              ecosystem@cinemo.com
            </a>
          </h2>
        </div>
      )}
    </div>
  );
};

export default Navbar;
