import React from "react";
import styles from "./styles/Legals.module.css";
import Navbar from "./components/Navbar";
const Imprint: React.FC = () => {
  return (
    <>
      <Navbar isItNavbar={true} legalPage={true} />

      <div className={styles.imprintContainer}>
        <h1>Imprint</h1>

        <div className={styles.imprintContent}>
          <h4>
            Cinemo GmbH <br />
            Kaiserstr. 161
            <br /> 76133 Karlsruhe
            <br /> Germany
          </h4>
          <h4>
            Email: contact (at) cinemo.com
            <br /> Web: www.cinemo.com{" "}
          </h4>
          <h4>
            HRB 705947 Registercourt Mannheim
            <br /> Geschaeftsfuehrer: Richard Lesser{" "}
          </h4>
          <h4>Value added tax identification number (UST.-ID): DE263064612 </h4>
        </div>
      </div>
      <Navbar isItNavbar={false} legalPage={true} />
    </>
  );
};
export default Imprint;
